.bg-families,
.bg-default {
  @apply bg-[#44978D];

  &.bg-reverse {
    @apply bg-[#99ca44];
  }
}

.bg-moms {
  @apply bg-[#FA8E73];

  &.bg-reverse {
    @apply bg-[#ae4c62];
  }
}

.bg-pros {
  @apply bg-[#006679];

  &.bg-reverse {
    @apply bg-[#44978d];
  }
}

.bg-learning {
  @apply bg-[#99CA44];

  &.bg-reverse {
    @apply bg-[#44978d];
  }
}

.bg-moms-gradient {
  background: linear-gradient(-90deg, #fa8e73 0%, #ae4c62 95.83%);

  &.gradient-reverse {
    background: linear-gradient(90deg, #ae4c62 0%, #fa8e73 95.83%);
  }
}

.bg-default-gradient {
  background: linear-gradient(90deg, #44978d 0%, #99ca44 100%);

  &.gradient-reverse {
    background: linear-gradient(90deg, #99ca44 0%, #44978d 100%);
  }
}

.bg-families-gradient {
  background: linear-gradient(90deg, #44978d 0%, #99ca44 100%);

  &.gradient-reverse {
    background: linear-gradient(90deg, #99ca44 0%, #44978d 100%);
  }
}

.bg-pros-gradient {
  background: linear-gradient(90deg, #44978d 0%, #006679 100%);
  &.gradient-reverse {
    background: linear-gradient(90deg, #006679 0%, #44978d 100%);
  }
}

.bg-transition-gradient {
  background: linear-gradient(180deg, #f8f8f8 0%, #ffffff 100%);
}

.bg-home-gradient {
  background: linear-gradient(-90deg, #80c04a 0%, #44978d 49%, #006679 100%);

  &.gradient-reverse {
    background: linear-gradient(90deg, #80c04a 0%, #44978d 49%, #006679 100%);
  }
}

.border-families,
.border-default {
  @apply border-[#44978D];

  &.border-reverse {
    @apply border-[#99ca44];
  }
}

.border-moms {
  @apply border-[#FA8E73];

  &.border-reverse {
    @apply border-[#ae4c62];
  }
}

.border-pros {
  @apply border-[#006679];

  &.border-reverse {
    @apply border-[#44978d];
  }
}

.border-learning {
  @apply border-[#99CA44];

  &.border-reverse {
    @apply border-[#44978d];
  }
}

.text-families,
.text-default {
  @apply text-[#44978D];
}

.text-learning {
  @apply text-[#99CA44];
}

.text-moms {
  @apply text-[#FA8E73];
}

.text-pros {
  @apply text-[#006679];
}

/* TODO: Remove this when we have a better solution */

.moms-hover .navLink {
  @apply hover:text-[#FA8E73];
}

.families-hover .navLink {
  @apply hover:text-[#44978D];
}

.pros-hover .navLink {
  @apply hover:text-[#006679];
}

.learning-hover .navLink {
  @apply hover:text-[#99CA44];
}

.default-hover .navLink {
  @apply hover:text-[#44978D];
}

/* rich-text-colorway */
.families-colorway .text-body-1 .rich-text-colorway,
.families-colorway .text-body-2 .rich-text-colorway,
.families-colorway .text-body-3 .rich-text-colorway,
.families-colorway .text-body-3-semi .rich-text-colorway,
.families-colorway .text-deco-head-2 .rich-text-colorway,
.families-colorway .text-deco-head-5 .rich-text-colorway,
.families-colorway .text-head-6 .rich-text-colorway,
.families-colorway .text-head-7 .rich-text-colorway,
.families-colorway .text-head-8 .rich-text-colorway,
.families-colorway .text-overline-2 .rich-text-colorway,
.families-colorway .text-quotation-3 .rich-text-colorway {
  @apply text-[#44978D];
}

.learning-colorway .text-body-1 .rich-text-colorway,
.learning-colorway .text-body-2 .rich-text-colorway,
.learning-colorway .text-body-3 .rich-text-colorway,
.learning-colorway .text-body-3-semi .rich-text-colorway,
.learning-colorway .text-deco-head-2 .rich-text-colorway,
.learning-colorway .text-deco-head-5 .rich-text-colorway,
.learning-colorway .text-head-6 .rich-text-colorway,
.learning-colorway .text-head-7 .rich-text-colorway,
.learning-colorway .text-head-8 .rich-text-colorway,
.learning-colorway .text-overline-2 .rich-text-colorway,
.learning-colorway .text-quotation-3 .rich-text-colorway {
  @apply text-[#99CA44];
}

.moms-colorway .text-body-1 .rich-text-colorway,
.moms-colorway .text-body-2 .rich-text-colorway,
.moms-colorway .text-body-3 .rich-text-colorway,
.moms-colorway .text-body-3-semi .rich-text-colorway,
.moms-colorway .text-deco-head-2 .rich-text-colorway,
.moms-colorway .text-deco-head-5 .rich-text-colorway,
.moms-colorway .text-head-6 .rich-text-colorway,
.moms-colorway .text-head-7 .rich-text-colorway,
.moms-colorway .text-head-8 .rich-text-colorway,
.moms-colorway .text-overline-2 .rich-text-colorway,
.moms-colorway .text-quotation-3 .rich-text-colorway {
  @apply text-[#FA8E73];
}

.pros-colorway .text-body-1 .rich-text-colorway,
.pros-colorway .text-body-2 .rich-text-colorway,
.pros-colorway .text-body-3 .rich-text-colorway,
.pros-colorway .text-body-3-semi .rich-text-colorway,
.pros-colorway .text-deco-head-2 .rich-text-colorway,
.pros-colorway .text-deco-head-5 .rich-text-colorway,
.pros-colorway .text-head-6 .rich-text-colorway,
.pros-colorway .text-head-7 .rich-text-colorway,
.pros-colorway .text-head-8 .rich-text-colorway,
.pros-colorway .text-overline-2 .rich-text-colorway,
.pros-colorway .text-quotation-3 .rich-text-colorway {
  @apply text-[#006679];
}

/* rich-text-gradient */
.pros-colorway .text-overline-2,
.pros-colorway .text-deco-head-2,
.pros-colorway .text-deco-head-3,
.pros-colorway .text-body-1 .rich-text-gradient,
.pros-colorway .text-body-2 .rich-text-gradient,
.pros-colorway .text-body-3 .rich-text-gradient,
.pros-colorway .text-body-3-semi .rich-text-gradient,
.pros-colorway .text-deco-head-2 .rich-text-gradient,
.pros-colorway .text-deco-head-5 .rich-text-gradient,
.pros-colorway .text-head-6 .rich-text-gradient,
.pros-colorway .text-head-7 .rich-text-gradient,
.pros-colorway .text-head-8 .rich-text-gradient,
.pros-colorway .text-overline-2 .rich-text-gradient,
.pros-colorway .text-quotation-3 .rich-text-gradient {
  @apply bg-pros-gradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.moms-colorway .text-overline-2,
.moms-colorway .text-deco-head-2,
.moms-colorway .text-deco-head-3,
.moms-colorway .text-body-1 .rich-text-gradient,
.moms-colorway .text-body-2 .rich-text-gradient,
.moms-colorway .text-body-3 .rich-text-gradient,
.moms-colorway .text-body-3-semi .rich-text-gradient,
.moms-colorway .text-deco-head-2 .rich-text-gradient,
.moms-colorway .text-deco-head-5 .rich-text-gradient,
.moms-colorway .text-head-6 .rich-text-gradient,
.moms-colorway .text-head-7 .rich-text-gradient,
.moms-colorway .text-head-8 .rich-text-gradient,
.moms-colorway .text-overline-2 .rich-text-gradient,
.moms-colorway .text-quotation-3 .rich-text-gradient {
  @apply bg-moms-gradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.families-colorway .text-overline-2,
.families-colorway .text-deco-head-2,
.families-colorway .text-deco-head-3,
.families-colorway .text-body-1 .rich-text-gradient,
.families-colorway .text-body-2 .rich-text-gradient,
.families-colorway .text-body-3 .rich-text-gradient,
.families-colorway .text-body-3-semi .rich-text-gradient,
.families-colorway .text-deco-head-2 .rich-text-gradient,
.families-colorway .text-deco-head-5 .rich-text-gradient,
.families-colorway .text-head-6 .rich-text-gradient,
.families-colorway .text-head-7 .rich-text-gradient,
.families-colorway .text-head-8 .rich-text-gradient,
.families-colorway .text-overline-2 .rich-text-gradient,
.families-colorway .text-quotation-3 .rich-text-gradient {
  @apply bg-families-gradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.default-colorway .text-overline-2,
.default-colorway .text-deco-head-2,
.default-colorway .text-deco-head-3,
.default-colorway .text-body-1 .rich-text-gradient,
.default-colorway .text-body-2 .rich-text-gradient,
.default-colorway .text-body-3 .rich-text-gradient,
.default-colorway .text-body-3-semi .rich-text-gradient,
.default-colorway .text-deco-head-2 .rich-text-gradient,
.default-colorway .text-deco-head-5 .rich-text-gradient,
.default-colorway .text-head-6 .rich-text-gradient,
.default-colorway .text-head-7 .rich-text-gradient,
.default-colorway .text-head-8 .rich-text-gradient,
.default-colorway .text-overline-2 .rich-text-gradient,
.default-colorway .text-quotation-3 .rich-text-gradient {
  @apply bg-default-gradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.families-colorway .text-deco-head-2,
.default-colorway .text-deco-head-2,
.learning-colorway .text-deco-head-2,
.moms-colorway .text-deco-head-2,
.pros-colorway .text-deco-head-2,
.families-colorway .text-deco-head-3,
.default-colorway .text-deco-head-3,
.learning-colorway .text-deco-head-3,
.moms-colorway .text-deco-head-3,
.pros-colorway .text-deco-head-3 {
  /* Important so the bg fills the entire line height, enabling the gradient to be applied through all text from top to bottom. */
  /* SEE: https://github.com/pairtreefamily/marketing-site-2/pull/19/files#r1539367840 */

  /* overriding with padding instead of line - height for typography  */
  @apply pb-4;
}

.text-white h1,
.text-white h2,
.text-white h3,
.text-white h4,
.text-white h5,
.text-white h6 {
  color: white;
}

.text-white-override,
.text-white-override h1,
.text-white-override h2,
.text-white-override h3,
.text-white-override h4,
.text-white-override h5,
.text-white-override h6,
.text-white-override .text-deco-head-2,
.text-white-override .text-deco-head-5,
.text-white-override .text-head-6,
.text-white-override .text-head-7,
.text-white-override .text-head-8,
.text-white-override .text-body-3-semi,
.text-white-override .text-body-2,
.text-white-override .text-body-3,
.text-white-override .text-body-1,
.text-white-override .text-quotation-3,
.text-white-override .text-overline-2 {
  color: white !important;
  background: transparent !important;
  -webkit-text-fill-color: #fff !important;
}

/* text */
.text-families,
.text-default {
  color: #44978d;
}

.text-moms {
  color: #fa8e73;
}

.text-pros {
  color: #006679;
}

.text-learning {
  color: #99ca44;
}

.text-gradient-default {
  @apply bg-default-gradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
