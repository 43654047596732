@layer components {
  .text-deco-head-1 {
    @apply font-rebrand;
    font-size: 60px;
    line-height: 65px;
    font-weight: 700;
    font-style: italic;
    letter-spacing: -0.01em;
  }

  .text-deco-head-1-lg {
    @apply font-rebrand;
    font-size: 70px;
    line-height: 75px;
    font-weight: 700;
    font-style: italic;
    letter-spacing: -0.01em;
  }

  .text-deco-head-2 {
    @apply font-rebrand;
    font-size: 55px;
    line-height: 60px;
    font-weight: 700;
    font-style: italic;
    letter-spacing: -0.01em;
  }

  .text-deco-head-3 {
    @apply font-rebrand;
    @apply text-heading-45;
    font-weight: 700;
    font-style: italic;
    letter-spacing: -0.01em;
  }

  .text-deco-head-4 {
    @apply font-rebrand;
    font-size: 40px;
    line-height: 45px;
    font-weight: 700;
    font-style: italic;
    letter-spacing: -0.01em;
  }

  .text-deco-head-5 {
    @apply font-rebrand;
    font-size: 35px;
    line-height: 40px;
    font-weight: 700;
    font-style: italic;
    letter-spacing: -0.01em;
  }

  .text-deco-head-6 {
    @apply font-rebrand;
    font-size: 25px;
    line-height: 30px;
    font-weight: 700;
    font-style: italic;
    letter-spacing: -0.01em;
  }

  .text-head-5 {
    @apply font-montserrat;
    @apply text-heading-30;
    font-weight: 700;
  }

  .text-head-6 {
    @apply font-montserrat;
    @apply text-heading-20;
    font-weight: 700;
  }

  .text-head-7 {
    @apply font-montserrat;
    @apply text-body-17;
    font-weight: 700;
  }

  .text-head-8 {
    @apply font-montserrat;
    @apply text-body-15;
    font-weight: 700;
    font-style: normal;
  }

  .text-body-1 {
    @apply font-montserrat;
    @apply text-body-20;
    font-weight: 400;
  }

  .text-body-2 {
    @apply font-montserrat;
    @apply text-body-17;
    font-weight: 400;
  }

  .text-body-3,
  input.text-body-3::placeholder {
    @apply font-montserrat;
    @apply text-body-15;
    font-weight: 400;
  }

  .text-body-4 {
    @apply font-montserrat;
    @apply text-body-13;
  }

  .text-body-12 {
    @apply font-montserrat;
    font-size: 12px;
  }

  .text-overline-1 {
    @apply font-montserrat;
    @apply text-body-17;
    font-weight: 700;
    letter-spacing: 0.035em;
  }

  .text-overline-2 {
    @apply font-montserrat;
    @apply text-body-15;
    font-weight: 700;
    letter-spacing: 0.035em;
  }

  .text-overline-3 {
    @apply font-montserrat;
    @apply text-body-13;
    font-weight: 700;
    letter-spacing: 0.035em;
  }

  .text-handwriting-1 {
    @apply font-gochi-hand;
    @apply text-handwriting-30;
  }

  .text-handwriting-2 {
    @apply font-gochi-hand;
    @apply text-handwriting-24;
  }

  .text-quote-1 {
    @apply font-rebrand;

    font-size: 30px;
    line-height: 40px;
    font-weight: 400;
    font-style: italic;
    letter-spacing: -0.01em;
  }

  .text-quote-2 {
    @apply font-rebrand;

    font-size: 25px;
    line-height: 32px;
    font-weight: 400;
    font-style: italic;
    letter-spacing: -0.01em;
  }

  .text-link-button {
    @apply font-montserrat;
    font-size: 14px;
    font-weight: 600;
  }
}
