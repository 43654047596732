@layer components {
  /* ADOPTION PATH */
  .path-a-full {
    stroke-dasharray: 0 1106;
    /* because path-a reconnects, the path is shorter than it appears visually.
     * easing out gives this away, so we just ease in
     */
    animation-name: path-a-full-draw;
    animation-duration: 2s;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
    animation-delay: 1s;
  }

  @keyframes path-a-full-draw {
    from {
      stroke-dasharray: 0 1106;
    }
    to {
      stroke-dasharray: 1106 0;
    }
  }

  .path-b-full {
    stroke-dasharray: 0 1254;
    animation-name: path-b-full-draw;
    animation-duration: 4s;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
    animation-delay: 2s;
  }

  @keyframes path-b-full-draw {
    from {
      stroke-dasharray: 0 1254;
    }
    to {
      stroke-dasharray: 1254 0;
    }
  }

  .path-c-full {
    stroke-dasharray: 0 2846;
    animation-name: path-c-full-draw;
    animation-duration: 5s;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
    animation-delay: 0;
  }

  @keyframes path-c-full-draw {
    from {
      stroke-dasharray: 0 2846;
    }
    to {
      stroke-dasharray: 2846 0;
    }
  }

  .path-d-full {
    stroke-dasharray: 0 1286;
    animation-name: path-d-full-draw;
    animation-duration: 4s;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
    animation-delay: 1s;
  }

  @keyframes path-d-full-draw {
    from {
      stroke-dasharray: 0 1286;
    }
    to {
      stroke-dasharray: 1286 0;
    }
  }

  /* ADOPTION PATH (medium)*/
  .path-a-medium {
    stroke-dasharray: 0 904;
    /* because path-a reconnects, the path is shorter than it appears visually.
     * easing out gives this away, so we just ease in
     */
    animation-name: path-a-medium-draw;
    animation-duration: 2s;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
    animation-delay: 1s;
  }

  @keyframes path-a-medium-draw {
    from {
      stroke-dasharray: 0 904;
    }
    to {
      stroke-dasharray: 904 0;
    }
  }

  .path-b-medium {
    stroke-dasharray: 0 1024;
    animation-name: path-b-medium-draw;
    animation-duration: 4s;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
    animation-delay: 2s;
  }

  @keyframes path-b-medium-draw {
    from {
      stroke-dasharray: 0 1024;
    }
    to {
      stroke-dasharray: 1024 0;
    }
  }

  .path-c-medium {
    stroke-dasharray: 0 2216;
    animation-name: path-c-medium-draw;
    animation-duration: 5s;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
    animation-delay: 0;
  }

  @keyframes path-c-medium-draw {
    from {
      stroke-dasharray: 0 2216;
    }
    to {
      stroke-dasharray: 2216 0;
    }
  }

  .path-d-medium {
    stroke-dasharray: 0 978;
    animation-name: path-d-medium-draw;
    animation-duration: 4s;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
    animation-delay: 1s;
  }

  @keyframes path-d-medium-draw {
    from {
      stroke-dasharray: 0 978;
    }
    to {
      stroke-dasharray: 978 0;
    }
  }

  /* ADOPTION PATH (small)*/
  .path-a-small {
    stroke-dasharray: 0 634;
    /* because path-a reconnects, the path is shorter than it appears visually.
     * easing out gives this away, so we just ease in
     */
    animation-name: path-a-small-draw;
    animation-duration: 2s;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
    animation-delay: 1s;
  }

  @keyframes path-a-small-draw {
    from {
      stroke-dasharray: 0 634;
    }
    to {
      stroke-dasharray: 634 0;
    }
  }

  .path-b-small {
    stroke-dasharray: 0 669;
    animation-name: path-b-small-draw;
    animation-duration: 4s;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
    animation-delay: 2s;
  }

  @keyframes path-b-small-draw {
    from {
      stroke-dasharray: 0 669;
    }
    to {
      stroke-dasharray: 669 0;
    }
  }

  .path-c-small {
    stroke-dasharray: 0 1363;
    animation-name: path-c-small-draw;
    animation-duration: 5s;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
    animation-delay: 0;
  }

  @keyframes path-c-small-draw {
    from {
      stroke-dasharray: 0 1363;
    }
    to {
      stroke-dasharray: 1363 0;
    }
  }

  .path-d-small {
    stroke-dasharray: 0 565;
    animation-name: path-d-small-draw;
    animation-duration: 4s;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
    animation-delay: 1s;
  }

  @keyframes path-d-small-draw {
    from {
      stroke-dasharray: 0 565;
    }
    to {
      stroke-dasharray: 565 0;
    }
  }
}
