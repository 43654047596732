@layer components {
  ul.checklist {
    list-style-type: none;
    padding: 0;
    @apply ml-0;
  }

  ul.checklist li {
    @apply relative mb-2 pl-8;
  }

  ul.checklist li::before {
    content: url("/checkmark.svg");
    height: 18px;
    width: 16px;
    @apply absolute left-0 top-1 inline-flex text-teal;
  }
}
