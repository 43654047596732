@layer components {
  .step-container:before {
    content: "";
    height: 5px;
    @apply bg-backgroundgray;
    position: absolute;
    top: 242px;
    right: 0;
    left: 0;
  }

  .step-container:first-of-type:before {
    width: 50%;
    left: 50%;
  }

  .step-container:last-of-type:before {
    width: 50%;
    right: 50%;
  }
}
