.cta-button {
  font-family: inherit;
  font-size: inherit;
  position: relative;
}

.rollover-animation {
  transition: transform 0.3s cubic-bezier(0.7, 0, 0.2, 1);
}

.rollover-animation:hover {
  @apply scale-[1.05];
}
