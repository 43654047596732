.hs-form {
  /* general form input */
  .field {
    @apply mb-4 w-full;
  }

  .field label {
    @apply text-body-4 mb-1 font-semibold text-onyx;
  }

  /* text input */
  .field .input input {
    @apply text-body-3 w-full border-b-[1px] border-solid border-lightgray bg-[transparent] font-normal text-onyx focus:outline-none;
  }

  .field .input input {
    @apply italic;
  }

  /* select input */
  .field.hs-fieldtype-select .input {
    @apply mt-1;
  }

  .field.hs-fieldtype-select .input select {
    @apply text-body-3 w-full border-b-[1px] border-solid border-lightgray bg-[transparent] py-1 font-normal italic text-onyx focus:outline-none;
    background: none;
  }

  /* radio input (https://moderncss.dev/pure-css-custom-styled-radio-buttons/) */
  .field.hs-fieldtype-radio .input ul {
    @apply mt-2 flex flex-row gap-4;
  }

  .field.hs-fieldtype-radio .input .hs-form-radio-display {
    @apply flex flex-row;
  }

  .field.hs-fieldtype-radio .input input[type="radio"] {
    @apply mr-2 h-[20px] w-[20px] appearance-none rounded-[50%] border border-solid border-lightgray bg-white;

    display: grid;
    place-content: center;
  }

  .field.hs-fieldtype-radio .input input[type="radio"]::before {
    content: "";
    width: 12px;
    height: 12px;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    background: currentColor;
  }

  .field.hs-fieldtype-radio .input input[type="radio"]:checked:before {
    transform: scale(1);
  }

  .field.hs-fieldtype-radio .input input[type="radio"] + span {
    @apply text-body-4 font-normal;
  }

  /* privacy policy: checkbox */
  .field .hs-form-booleancheckbox-display {
    @apply flex gap-2;
  }

  .field .hs-form-booleancheckbox-display span {
    @apply font-montserrat text-body-11;
    font-weight: 400;
  }

  .field .hs-form-checkbox-display {
    @apply flex;
    margin-bottom: 0.75rem !important;
  }

  .field .hs-form-checkbox-display input[type="checkbox"] {
    @apply rounded;

    width: 20px !important;
    height: 20px !important;
    outline: 1px solid #d9d9d9 !important;
    appearance: none;
    background: #fff;
  }

  .field .hs-form-checkbox-display input[type="checkbox"]:before {
    content: "✓";
    font-size: 16px;
    font-weight: 600;
    display: block;
    text-align: center;
  }

  .field .hs-form-checkbox-display input[type="checkbox"]:checked:before {
    color: black !important;
  }

  .field .hs-form-checkbox .hs-input {
    margin-right: 0.5rem;
  }

  .field.hs-fieldtype-checkbox.hs-form-field .input {
    @apply mt-2;
  }

  /* form messages */
  .field .hs-form-required {
    @apply pl-1;
  }

  .field .hs-error-msg,
  .field .hs-error-msgs,
  .field .hs-error-msgs a,
  .field .hs-form-required {
    @apply text-body-5 mt-1 text-red-600;
  }

  .field .hs-error-msgs a {
    @apply text-body-5 text-darkgray;
  }

  /* terms of agreement: richtext */
  .legal-consent-container {
    @apply mb-2 text-center font-montserrat text-body-11;
    font-weight: 400;
  }

  .legal-consent-container a {
    @apply font-bold;
  }

  /* recaptcha */
  .field.hs-recaptcha .input {
    @apply mb-2 flex justify-center;
  }

  /* submit button */
  .hs-submit {
    @apply text-body-3-medium inline-block w-full cursor-pointer rounded-full border-2 bg-gray px-8 py-2 text-center font-semibold text-white disabled:opacity-50;
  }

  .hs_error_rollup {
    @apply text-body-5 mb-2 text-center text-red-600;
  }
}

/* submitted state */
.hs-main-font-element.submitted-message {
  @apply text-body-4 mb-2 flex h-full items-center text-center font-normal text-white;
}

.hubspot-form-wrapper.bg-families,
.hubspot-form-wrapper.bg-default,
.hubspot-form-wrapper.bg-moms,
.hubspot-form-wrapper.bg-pros,
.hubspot-form-wrapper.bg-learning {
  @apply bg-backgroundgray;
}

.hubspot-form-wrapper.bg-families .hs-submit,
.hubspot-form-wrapper.bg-default .hs-submit {
  @apply border-[#44978D] bg-[#44978D];
}

.hubspot-form-wrapper.bg-moms .hs-submit {
  @apply border-[#FA8E73] bg-[#FA8E73];
}

.hubspot-form-wrapper.bg-pros .hs-submit {
  @apply border-[#006679] bg-[#006679];
}

.hubspot-form-wrapper.bg-learning .hs-submit {
  @apply border-[#99CA44] bg-[#99CA44];
}

/* variant override */
.hubspot-form-wrapper.variant-bg-transparent {
  background-color: transparent !important;

  .field label {
    @apply text-white;
  }

  /* text input */
  .hs-form .field .input input {
    @apply text-white;
  }

  /* select input */
  .field.hs-fieldtype-select .input select {
    @apply text-white;
  }

  /* error messages  */
  .field .hs-error-msg,
  .field .hs-error-msgs,
  .field .hs-error-msgs a,
  .field .hs-form-required {
    @apply text-red-600;
  }
}
