@layer components {
  .badge-background {
    position: relative;
    clip-path: polygon(0% 0%, 0% 90%, 50% 100%, 100% 90%, 100% 0%);
  }

  .badge-foreground {
    position: absolute;
    clip-path: polygon(0% 0%, 0% 90%, 50% 100%, 100% 90%, 100% 0%);
    z-index: 2;
    top: -0.1rem;
    left: -0.2rem;
    width: 100%;
    height: 100%;
    transform: scale(0.97);
  }

  .badge-background-sm {
    position: relative;
    clip-path: polygon(0% 100%, 90% 100%, 100% 50%, 90% 0%, 0% 0%);
  }

  .badge-foreground-sm {
    position: absolute;
    clip-path: polygon(0% 100%, 90% 100%, 100% 50%, 90% 0%, 0% 0%);
    z-index: 2;
    top: -0.1rem;
    left: -1rem;
    width: calc(100% + 1rem);
    height: 100%;
    transform: scale(0.97);
  }
}
