@layer components {
  .home-hero-1-a {
    animation: fade-in ease-out 750ms forwards;
    opacity: 0;
  }

  .home-hero-1-b {
    animation: fade-in ease-out 750ms forwards;
    animation-delay: 750ms;
    opacity: 0;
  }

  .home-hero-1 {
    animation: fade-out ease-in 750ms forwards;
    animation-delay: 1500ms;
  }
  /* first wave of text is gone by 2250 */

  .home-hero-2-a {
    animation: fade-in ease-out 750ms forwards;
    animation-delay: 2500ms;
    opacity: 0;
  }
  /* second wave of text is out by 3250 */

  /* give half a second extra before "- for everyone" comes out */
  .home-hero-2-b {
    animation: fade-in ease-out 750ms forwards;
    animation-delay: 3750ms;
    opacity: 0;
  }

  .home-hero-links {
    animation: fade-in 1000ms ease-out forwards;
    animation-delay: 4500ms;
    opacity: 0;
  }

  /*
   *ATENTION! Don't remove this until you are sure it is not being used in mom page hero (fade-out and fade-in)
  */
  @keyframes fade-out {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  @keyframes fade-in-out {
    0% {
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
    60% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}

.fade-animation {
  transition: opacity 1000ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.hide {
  transition: opacity 1000ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
}

.show {
  transition: opacity 500ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 1;
}
