@layer utilities {
  .quote::before {
    content: open-quote;
  }

  .quote::after {
    content: close-quote;
  }

  /* how to generate filters based on hexcolor: https://codepen.io/sosuke/pen/Pjoqqp */
  .filter-darkblue {
    filter: invert(29%) sepia(94%) saturate(567%) hue-rotate(145deg)
      brightness(91%) contrast(101%);
  }
  .filter-green {
    filter: invert(65%) sepia(76%) saturate(328%) hue-rotate(50deg)
      brightness(91%) contrast(90%);
  }
  .filter-lightgreen {
    filter: invert(99%) sepia(92%) saturate(1383%) hue-rotate(8deg)
      brightness(93%) contrast(78%);
  }
  .filter-teal {
    filter: invert(60%) sepia(2%) saturate(5965%) hue-rotate(113deg)
      brightness(86%) contrast(88%);
  }
  .filter-white {
    filter: brightness(0) invert(1);
  }
}
