.family-mosaic {
  display: grid;
  grid-template-columns: repeat(32, minmax(auto, 12px));
  grid-template-rows: repeat(32, minmax(auto, 12px));
  grid-template-areas:
    "a a a a a a a a a a a a a a . b b b b b b b b b b b b b b b b b"
    "a a a a a a a a a a a a a a . b b b b b b b b b b b b b b b b b"
    "a a a a a a a a a a a a a a . b b b b b b b b b b b b b b b b b"
    "a a a a a a a a a a a a a a . b b b b b b b b b b b b b b b b b"
    "a a a a a a a a a a a a a a . b b b b b b b b b b b b b b b b b"
    "a a a a a a a a a a a a a a . b b b b b b b b b b b b b b b b b"
    "a a a a a a a a a a a a a a . b b b b b b b b b b b b b b b b b"
    "a a a a a a a a a a a a a a . b b b b b b b b b b b b b b b b b"
    "a a a a a a a a a a a a a a . b b b b b b b b b b b b b b b b b"
    "a a a a a a a a a a a a a a . b b b b b b b b b b b b b b b b b"
    "a a a a a a a a a a a a a a . b b b b b b b b b b b b b b b b b"
    "a a a a a a a a a a a a a a . b b b b b b b b b b b b b b b b b"
    "a a a a a a a a a a a a a a . b b b b b b b b b b b b b b b b b"
    "a a a a a a a a a a a a a a . b b b b b b b b b b b b b b b b b"
    "a a a a a a a a a a a a a a . . . . . . . . . . . . . . . . . ."
    "a a a a a a a a a a a a a a . . . . d d d d d d d d d d d d d d"
    "a a a a a a a a a a a a a a . . . . d d d d d d d d d d d d d d"
    ". . . . . . . . . . . . . . . . . . d d d d d d d d d d d d d d"
    "c c c c c c c c c c c c c c c c c . d d d d d d d d d d d d d d"
    "c c c c c c c c c c c c c c c c c . d d d d d d d d d d d d d d"
    "c c c c c c c c c c c c c c c c c . d d d d d d d d d d d d d d"
    "c c c c c c c c c c c c c c c c c . d d d d d d d d d d d d d d"
    "c c c c c c c c c c c c c c c c c . d d d d d d d d d d d d d d"
    "c c c c c c c c c c c c c c c c c . d d d d d d d d d d d d d d"
    "c c c c c c c c c c c c c c c c c . d d d d d d d d d d d d d d"
    "c c c c c c c c c c c c c c c c c . d d d d d d d d d d d d d d"
    "c c c c c c c c c c c c c c c c c . d d d d d d d d d d d d d d"
    "c c c c c c c c c c c c c c c c c . d d d d d d d d d d d d d d"
    "c c c c c c c c c c c c c c c c c . d d d d d d d d d d d d d d"
    "c c c c c c c c c c c c c c c c c . d d d d d d d d d d d d d d"
    "c c c c c c c c c c c c c c c c c . d d d d d d d d d d d d d d"
    "c c c c c c c c c c c c c c c c c . d d d d d d d d d d d d d d";
}

@media (min-width: 768px) {
  .family-mosaic {
    grid-template-columns: repeat(32, minmax(auto, 16px));
    grid-template-rows: repeat(32, minmax(auto, 16px));
  }
}

.family-mosaic .grid-area-0 {
  grid-area: a;
  /* FOR DEBUGGING PURPOSES ONLY */
  /* background-color: #8ca0ff; */
}

.family-mosaic .grid-area-1 {
  grid-area: b;
  /* FOR DEBUGGING PURPOSES ONLY */
  /* background-color: #ffa08c; */
}

.family-mosaic .grid-area-2 {
  grid-area: c;
  /* FOR DEBUGGING PURPOSES ONLY */
  /* background-color: #ffff64; */
}

.family-mosaic .grid-area-3 {
  grid-area: d;
  /* FOR DEBUGGING PURPOSES ONLY */
  /* background-color: #8cffa0; */
}
