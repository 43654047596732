@layer components {
  .carousel .carousel__dot-group {
    display: flex;
    gap: 8px;
  }

  .carousel.testimonial .carousel__dot-group {
    justify-content: center;
  }

  .carousel .carousel__dot {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: #d9d9d9;
  }
  .carousel:not(.testimonial) .carousel__dot--selected {
    @apply bg-teal;
  }

  .carousel.testimonial .carousel__dot--selected {
    @apply bg-teal-shade;
  }

  .carousel .carousel__slide {
    height: 100%;
  }

  .carousel .carousel__inner-slide {
    position: static !important;
  }
}
