@layer components {
  .flip-card {
    background-color: transparent;
    width: 390px;
    height: 231px;
    perspective: 1000px; /* Remove this if you don't want the 3D effect */
  }

  /* This container is needed to position the front and back side */
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }

  /* Do an horizontal flip when you move the mouse over the flip box container */
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }

  /* Position the front and back side */
  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;

    -webkit-perspective: 0;
    -webkit-transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  /* Style the front side (fallback if image is missing) */
  .flip-card-front {
    width: 390px;
    height: 231px;
    color: black;
  }

  /* Style the back side */
  .flip-card-back {
    width: 390px;
    height: 231px;
    color: white;
    @apply bg-teal;
    border-radius: 15px;
    transform: rotateY(180deg);
    padding: 25px;
    @apply font-montserrat;
    @apply text-body-15;
    font-weight: 600;
    box-shadow: 4px 4px 0px #006679;
  }
}
