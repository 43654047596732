@layer components {
  /* heading type */
  .text-head-1 {
    @apply font-montserrat;
    @apply text-heading-65;
    font-weight: 700;
  }

  .text-head-1 > em {
    @apply font-rebrand;
    @apply text-heading-75;
    font-weight: 700;
  }

  .text-head-2 {
    @apply font-montserrat;
    @apply text-heading-46;
    font-weight: 700;
  }

  .text-head-2 > em {
    @apply font-rebrand;
    @apply text-heading-55;
    font-style: italic;
    font-weight: 700;
  }

  .text-head-3 {
    @apply font-montserrat;
    @apply text-heading-38;
    font-weight: 700;
  }

  .text-head-3 > em {
    @apply font-rebrand;
    @apply text-heading-45;
    font-style: italic;
    font-weight: 700;
  }

  .text-head-4 {
    @apply font-montserrat;
    font-style: normal;
    font-size: 30px;
    line-height: 40px;
    font-weight: 700;
  }

  .text-head-4 > em {
    @apply font-rebrand;
    font-style: italic;
    font-size: 35px;
    line-height: 40px;
    font-weight: 700;
  }

  .text-head-5 {
    @apply font-montserrat;
    @apply text-heading-30;
    font-weight: 700;
  }

  .text-head-5 > em {
    @apply font-rebrand;
    @apply text-heading-30;
    font-weight: 700;
    font-style: italic;
  }

  .text-head-6 {
    @apply font-montserrat;
    @apply text-heading-24;
    font-weight: 700;
  }

  .text-head-7 {
    @apply font-montserrat;
    @apply text-heading-20;
    font-weight: 700;
    color: black;
    /* no h7 html element */
  }

  /* TODO: utility class? reorder headings? */
  .text-head-8 {
    @apply font-rebrand;
    @apply text-heading-40;
    font-weight: 700;
    font-style: italic;
  }

  /* body type */
  .text-body-1 {
    @apply font-montserrat;
    @apply text-body-20;
    font-weight: 400;
  }

  .text-body-1-medium {
    @apply font-montserrat;
    @apply text-body-20;
    font-weight: 500;
  }

  .text-body-2 {
    @apply font-montserrat;
    @apply text-body-17;
    font-weight: 400;
  }

  .text-body-2-medium {
    @apply font-montserrat;
    @apply text-body-17;
    font-weight: 500;
  }

  .text-body-2-semi {
    @apply font-montserrat;
    @apply text-body-17;
    font-weight: 600;
  }

  .text-body-3 {
    @apply font-montserrat;
    @apply text-body-15;
    font-weight: 400;
  }

  .text-body-3-medium {
    @apply font-montserrat;
    @apply text-body-15;
    font-weight: 500;
  }

  .text-body-3-semi {
    @apply font-montserrat;
    @apply text-body-15;
    font-weight: 600;
  }

  .text-body-4 {
    @apply font-montserrat;
    @apply text-body-13;
    font-weight: 400;
  }

  .text-body-4-medium {
    @apply font-montserrat;
    @apply text-body-13;
    font-weight: 500;
  }

  .text-body-4-semi {
    @apply font-montserrat;
    @apply text-body-13;
    font-weight: 600;
  }

  .text-body-5 {
    @apply font-montserrat;
    @apply text-body-11;
    font-weight: 400;
  }

  /* utility type */
  .text-overline-1 {
    @apply font-montserrat;
    @apply text-body-15;
    text-transform: uppercase;
    font-weight: 700;
  }

  .text-overline-2 {
    @apply font-montserrat;
    @apply text-body-13;
    text-transform: uppercase;
    font-weight: 700;
  }

  .text-overline-3 {
    @apply font-montserrat;
    @apply text-body-17;
    text-transform: uppercase;
    font-weight: 700;
  }

  .text-handwriting-1 {
    @apply font-gochi-hand;
    @apply text-handwriting-60;
  }

  .text-handwriting-2 {
    @apply font-gochi-hand;
    @apply text-handwriting-30;
  }

  .text-handwriting-3 {
    @apply font-gochi-hand;
    @apply text-handwriting-24;
  }

  .text-handwriting-4 {
    @apply font-gochi-hand;
    @apply text-handwriting-18;
  }

  .text-quotation-1 {
    @apply font-rebrand;
    @apply text-heading-45;
    font-weight: 400;
    font-style: italic;
  }

  .text-quotation-2 {
    @apply font-rebrand;
    @apply text-heading-35;
    font-weight: 400;
    font-style: italic;
  }

  .text-quotation-3 {
    @apply font-rebrand;
    @apply text-heading-40;
    font-weight: 400;
    font-style: italic;
  }
}
